if ('define' in window) {
define("discourse/theme-10/discourse/templates/connectors/above-latest-topic-list-item/clickable-topic-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="hidden"
    {{did-insert this.registerClickHandler}}
    {{will-destroy this.removeClickHandler}}
  ></div>
  */
  {
    "id": null,
    "block": "[[[11,0],[24,0,\"hidden\"],[4,[38,0],[[30,0,[\"registerClickHandler\"]]],null],[4,[38,1],[[30,0,[\"removeClickHandler\"]]],null],[12],[13]],[],false,[\"did-insert\",\"will-destroy\"]]",
    "moduleName": "discourse/theme-10/discourse/templates/connectors/above-latest-topic-list-item/clickable-topic-row",
    "isStrictMode": false
  });
});
}
