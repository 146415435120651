if ('define' in window) {
define("discourse/theme-10/discourse/connectors/above-latest-topic-list-item/clickable-topic-row", ["exports", "@glimmer/component", "@ember/object", "discourse/components/topic-list-item", "discourse/lib/intercept-click", "discourse-common/utils/decorators"], function (_exports, _component, _object, _topicListItem, _interceptClick, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(10);
  const themePrefix = key => `theme_translations.10.${key}`;
  class _default extends _component.default {
    clickHandler(event) {
      const targetElement = event.target;
      const topic = this.args.outletArgs.topic;
      if (targetElement.tagName === "DIV") {
        if ((0, _interceptClick.wantsNewWindow)(event)) {
          return true;
        }
        return _topicListItem.navigateToTopic.call(this, topic, topic.lastUnreadUrl);
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "clickHandler", [_decorators.bind]))();
    registerClickHandler(element) {
      element.parentElement.addEventListener("click", this.clickHandler);
    }
    static #_2 = (() => dt7948.n(this.prototype, "registerClickHandler", [_object.action]))();
    removeClickHandler(element) {
      element.parentElement.removeEventListener("click", this.clickHandler);
    }
    static #_3 = (() => dt7948.n(this.prototype, "removeClickHandler", [_object.action]))();
  }
  _exports.default = _default;
});
}
